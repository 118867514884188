import React, { useEffect, useState } from 'react'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import Card from "@mui/material/Card";
import { useFetch } from 'hooks/useFetch';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { Link, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import MDButton from 'components/MDButton';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useWindowSize } from 'react-use';

const Pages = () => {

    const { width } = useWindowSize()


    const Columns = ({ content }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {content || "-"}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const ImgColumns = ({ content }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
                {content ? <img height={100} width={100} className="object-contain" src={content} alt="logo-img" /> : <ImageNotSupportedIcon />}
            </MDBox>
        </MDBox>
    );

    const Actions = ({ pageurl }) => {
        return <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to={`${pageurl}`}>
                <MDButton variant="outlined" color="primary">
                    More
                </MDButton>
            </Link>
        </Box>
    }

    const [rows, setRows] = useState([])

    const navigate = useNavigate()

    const onSuccess = (response) => {
        console.log("view all Pages response", response)
    }

    const onError = (error) => {
        console.error("view all Pages error", error)
        if (error.status === 401) {
            navigate("/sign-in")
        }
    }

    const { isLoading, data, status } = useFetch("viewAllPages", "/get-pages.php?page=1", onSuccess, onError)

    const mobileSize = 870

    useEffect(() => {
        (data && status === "success") && setRows(data?.links.map((link, index) => {
            return {
                serialno: <Columns content={index + 1} />,
                logoimg: <ImgColumns content={link.logoimg} />,
                pageurl: <Columns content={link.pageurl} />,
                pagetitle: <Columns content={
                    <MDBox sx={{ maxWidth: "270px" }}>
                        <MDBox fontWeight="medium" lineHeight={1} >
                            {link.pagetitle}
                        </MDBox>
                        {width <= mobileSize && <MDBox  fontWeight="normal" fontSize="small">
                            {link.pageurl}
                        </MDBox>}
                    </MDBox >
                } />,
                // familyname: <Columns content={
                //     <MDTypography variant="inherit" color="info" textGradient={true} >
                //         <Link className='underline' to={`/all-families/${link.familyid}`}>{link.familyname}</Link>
                //     </MDTypography>
                // } />,
                // description: <Columns content={link.description} />,
                actions: <Actions pageurl={link.pageurl} />
            }
        }))

        return () => {
            setRows([])
        }
    }, [data, status, width])


    const [columns, setColumns] = useState([
        { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true, width: "10%" },
        { Header: "Logo Image", accessor: "logoimg", align: "left", id: 2, show: true, width: "15%" },
        { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: true, width: "25%" },
        { Header: "Page Title", accessor: "pagetitle", align: "left", id: 4, show: true, width: "35%" },
        { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "15%" },
    ])

    useEffect(() => {
        if (width <= mobileSize) {
            setColumns([
                { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true, width: "10%" },
                { Header: "Logo Image", accessor: "logoimg", align: "left", id: 2, show: false },
                { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: false },
                { Header: "Page Title", accessor: "pagetitle", align: "left", id: 4, show: true, width: "70%" },
                { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "20%" },
            ])
        } else {
            setColumns([
                { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true, width: "10%" },
                { Header: "Logo Image", accessor: "logoimg", align: "left", id: 2, show: true, width: "15%" },
                { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: true, width: "25%" },
                { Header: "Page Title", accessor: "pagetitle", align: "left", id: 4, show: true, width: "35%" },
                { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "15%" },
            ])
        }
    }, [width])

    return (
        <>
            {isLoading ? <Box sx={{ display: 'flex', justifyContent: "center" }}><CircularProgress /></Box> :
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={2}
                        px={2}
                        bgColor="inherit"
                        borderRadius="lg"
                        coloredShadow="dark"
                    >
                        <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                            <MDTypography variant="h6" color="dark" >
                                All Pages Information ( Total Pages: {data?.total} )
                            </MDTypography>

                            <Link to="/new-page">
                                <MDButton variant="gradient" color="primary">
                                    Create New Page
                                </MDButton>
                            </Link>
                        </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        // canSearch={true}
                        // search={search}
                        // setSearch={setSearch}
                        />
                    </MDBox>
                </Card>}


        </>)
}

export default Pages