import { Card, CardContent, Chip, CircularProgress, List, ListItem, ListItemText } from '@mui/material'
import MDBox from 'components/MDBox'
import { useFetch } from 'hooks/useFetch'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UIDateFormatWT } from 'utilities/commonFunctions'
import { Box } from '@mui/system'
import MDTypography from 'components/MDTypography'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import MDButton from 'components/MDButton'
import { baseUrl } from 'services/baseUrl'
import StarsIcon from '@mui/icons-material/Stars';


const ViewPage = () => {

    const { url } = useParams()

    const navigate = useNavigate()

    const onSuccess = (response) => {
        console.log("View Page Response", response)
    }

    const onError = (error) => {
        console.log("View Page Error", error)
        if (error.status === 401) {
            navigate("/sign-in")
        }
    }

    const { isLoading, data } = useFetch(["viewpage", url], `/get-page.php?pageurl=${url}`, onSuccess, onError)

    console.log('ViewPage  data:', data)

    const DataView = ({ primary, secondary }) => {
        return <ListItem sx={{ marginBottom: "5px", width: "100%", height: "auto" }}>
            <ListItemText sx={{ width: "50%", height: "auto" }} primary={<Chip sx={{ height: "auto" }} label={<MDTypography component="span" sx={{ whiteSpace: "pre-wrap" }} color="info">{primary}</MDTypography>} />} />
            <ListItemText sx={{ width: "50%", height: "auto", paddingLeft: "6px" }} secondary={<MDTypography component="span" sx={{ whiteSpace: "pre-wrap" }} >{secondary}</MDTypography>} />
        </ListItem>
    }

    const { data: feedbacks } = useFetch("feedbacks", `/get-feedbacks.php?pageurl=${url}`, () => { }, () => { }, !!url, (data) => data.feedbacks)

    const thisPageFeedBack = feedbacks && feedbacks?.filter((item) => item.pageurl === url)


    return (
        isLoading ? <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </Box>
            :
            <>
                <MDBox className="flex justify-end items-center mb-4">

                    <a style={{ marginRight: "8px" }} target='_blank' rel="noreferrer" href={`${baseUrl}/${data.page.pageurl}`}>
                        <MDButton color="primary">
                            Preview Page
                        </MDButton>
                    </a>

                    <Link to={`/new-page/${data.page.pageurl}`}>
                        <MDButton color="primary">
                            Edit Page
                        </MDButton>
                    </Link>

                </MDBox>

                <MDBox className='grid grid-cols-1 gap-4 md:grid-cols-2'>

                    <Card className='row-span-2' sx={{ minWidth: 275 }}>
                        <CardContent >

                            <MDBox sx={{ marginTop: "12px", marginBottom: "12px" }} >
                                <MDTypography variant="h3">{data?.page?.pagetitle}</MDTypography>
                            </MDBox>
                            {/* <MDTypography component="span">
                                {data?.member?.gender === "Male" ? <ManIcon color='inherit' fontSize='large' />
                                    :
                                    data?.member?.gender === "Female" ? <WomanIcon color='inherit' fontSize='large' /> : null}
                            </MDTypography> */}

                            <MDTypography component="div" variant="body2">
                                <List component="nav" aria-label="mailbox folders">

                                    {data?.page?.logoimg ? <img src={data?.page?.logoimg} alt={"Logo-pic"} className="object-cover my-3" /> : <span className='flex justify-start pl-3 pb-2 items-baseline gap-2'>
                                        <ImageNotSupportedIcon /> Logo image not provided</span>}


                                    <DataView primary={"Plan"} secondary={data?.page?.plan || "-"} />
                                    <DataView primary={"Page URL"} secondary={data?.page?.pageurl || "-"} />
                                    <DataView primary={"Page Title"} secondary={data?.page?.pagetitle || "-"} />
                                    <DataView primary={"description"} secondary={data?.page?.description || "-"} />

                                </List>

                            </MDTypography>
                        </CardContent>
                    </Card>

                    <Card sx={{ minWidth: 275 }}>
                        <CardContent sx={{ padding: "24px" }}>

                            {/* <MDTypography sx={{ marginTop: "12px", marginBottom: "12px" }} variant="h5" component="div">
                            Eric
                        </MDTypography> */}

                            <MDTypography component="div" variant="body2">

                                <List component="nav" aria-label="mailbox folders">
                                    {data?.page?.coverimg ? <img src={data?.page?.coverimg} alt={"Cover-pic"} className="object-cover" /> : <span className='flex justify-start pl-3 pb-2 items-baseline gap-2'>
                                        <ImageNotSupportedIcon /> Cover image not provided</span>}
                                </List>

                            </MDTypography>
                        </CardContent>
                    </Card>

                    <Card sx={{ minWidth: 275 }}>
                        <CardContent sx={{ padding: "24px" }}>

                            {/* <MDTypography sx={{ marginTop: "12px", marginBottom: "12px" }} variant="h5" component="div">
                            Eric
                        </MDTypography> */}

                            <MDTypography component="div" variant="body2">

                                <List component="nav" aria-label="mailbox folders">

                                    <DataView primary={"Views"} secondary={data?.page?.views || "-"} />
                                    <DataView primary={"Status"} secondary={data?.page?.status || "-"} />
                                    <DataView primary={"Added Date"} secondary={UIDateFormatWT(data?.page?.addeddate) || "-"} />
                                    <DataView primary={"Updated Date"} secondary={UIDateFormatWT(data?.page?.updateddate) || "-"} />

                                </List>

                            </MDTypography>
                        </CardContent>
                    </Card>

                </MDBox>

                <MDBox mt={3}>

                    <Card>
                        <CardContent sx={{ padding: "24px" }}>

                            <MDTypography sx={{ marginBottom: "12px" }} variant="h5" component="div">
                                Feedbacks
                            </MDTypography>

                            <MDTypography component="div" variant="body2">

                                {thisPageFeedBack?.length > 0 && thisPageFeedBack?.map((item, index) => {
                                    return <Feedback key={index} feedback={item} />
                                })}

                            </MDTypography>

                        </CardContent>
                    </Card>
                </MDBox>
            </>
    )
}

export default ViewPage

const Feedback = ({ feedback }) => {


    return <Card sx={{ marginBottom: "12px" }}>
        <CardContent sx={{ padding: "24px" }}>

            <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <MDTypography sx={{ marginBottom: "12px" }} variant="h6" component="div">
                    {feedback?.feedback || "-"}
                </MDTypography>

                <Stars rating={feedback?.stars} />

            </MDBox>


            <MDBox sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", columnGap: "50px" }}>
                <MDTypography variant="body1" component="div">
                    Name: {feedback?.name || "-"}
                </MDTypography>

                <MDTypography variant="body1" component="div">
                    Date: {UIDateFormatWT(feedback?.feedbackdate) || "-"}
                </MDTypography>

                <MDTypography variant="body1" component="div">
                    Phone: {feedback?.phoneno || "-"}
                </MDTypography>
            </MDBox>

        </CardContent>
    </Card>
}

const Stars = ({ rating }) => {

    const colorRating = [
        "#FF0000",
        "#FF0000",
        "#E1E100",
        "#E1E100",
        "green"
    ]

    const starsColor = colorRating[parseInt(rating) - 1]

    return <MDTypography
        sx={{
            marginBottom: "12px",
            display: "flex", gap: "2px", justifyContent: "center", alignItems: "center"
        }}
        variant="h6" component="div">
        {Array(5).fill("stars").map((item, index) => {
            return <StarsIcon sx={{ color: parseInt(rating) >= index + 1 ? starsColor : "rgba(79, 79, 79, 0.5)" }} fontSize='medium' key={index} name={item} />
        })}

    </MDTypography>

}
