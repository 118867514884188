import { Container } from "@mui/material";
import bgImage from "assets/images/bg-desktop.jpg";
import HomePageLayout from "layouts/components/HomePageLayout";
import MainSection from "layouts/reusable/MainSection";
import { contactMainContent } from "static/homepage/data";

export const Contact = () => {

    const { topic, content, image } = contactMainContent
    return (
        <HomePageLayout
            bgColor={"inherit"}
            image={bgImage}
            pageName="Contact"
            coverHeight=""
            heroText=""
        >

            <Container>
                <MainSection topic={topic} content={content} image={image} />
            </Container>


        </HomePageLayout>
    );
}

