import React from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
} from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';


const ProductItem = ({ title, description, imageUrl, price, productId, isAddedTocart, isPaid, handleAddToCart }) => {
    return (
        <Card className='postion-relative'>
            <CardMedia
                component="img"
                height="194"
                className='object-cover'
                image={imageUrl}
                alt={title}
            />
            <CardContent>
                <MDTypography sx={{ fontWeight: "bold" }}>
                    {title}
                </MDTypography>
                <MDTypography sx={{ fontSize: "1.1rem" }}>
                    {description}
                </MDTypography>
                <MDTypography>
                    <span style={{ fontWeight: "semi-bold", fontSize: "1.2rem" }}>Price:</span> {price} ₹
                </MDTypography>
            </CardContent>
            <CardActions>
                <MDButton sx={{mx: "auto"}} disabled={isAddedTocart || isPaid} variant="contained" color={isPaid ? "success" : "primary"} onClick={() => handleAddToCart(productId)}>
                    {isPaid ? "Purchased" : isAddedTocart ? "Added to cart" : "Add to Cart"}
                </MDButton>
            </CardActions>
        </Card>
    );
};

export default ProductItem