// prop-types is a library for typechecking of props

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";


function Footer() {

  const [controller,] = useMaterialUIController()

  const {
    darkMode,
  } = controller;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      mt={1.5}
      sx={{ backgroundColor: darkMode ? "#344767" : "#fff", color: darkMode ? "#fff" : "#999" }}
    >

      <MDBox color="inherit" sx={(theme) => {
        return {
          fontSize: "0.9rem",
          [theme.breakpoints.down('sm')]: { 
            fontSize: "0.9rem",
            padding: "6px",
          },
        }
      }}>
        © MyBReview.com 2023, All Right Reserved
      </MDBox>

      <MDBox>

      </MDBox>


    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {

};

// Typechecking props for the Footer
Footer.propTypes = {

};

export default Footer;
