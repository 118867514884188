import * as yup from 'yup';

const schema1 = yup
    .object()
    .shape({
        // plan: yup.object().required("Plan Field is Required").nullable(),
        pageurl: yup.string().required("Page URL is Required").matches(/^(\S+$)/g, 'Page URL cannot contain any blankspaces')
            .strict(true),
        pagetitle: yup.string().required("Page title is Required"),
        // .required("Page Description is Required"),
        logoimg: yup.string(),
        coverimg: yup.string(),
        description: yup.string(),

    })
    .required()

const schema2 = yup
    .object()
    .shape({
        links: yup.array().of(
            yup.object().shape({
                linktitle: yup.string().required("Link Title is Required"),
                linkurl: yup.string().required("Link URL is Required"),
                linkicon: yup.object().required("Link Icon is Required").nullable(),
                linkdescription: yup.string(),
            })
        ).min(1),
    //     socials: yup.array().of(
    //         yup.object().shape({
    //             linktitle: yup.string().required("Link Title is Required"),
    //             linkurl: yup.string().required("Link URL is Required"),
    //             linkicon: yup.object().required("Link Icon is Required").nullable(),
    //             linkdescription: yup.string(),
    //         })
    //     ).min(1),
    })
    .required()

const schema3 = yup
    .object()
    .shape({
        themeid: yup.object().required("Theme Id is Required").nullable(), // By default 1 ("light theme") 2 ("dark theme") 
        themedetails: yup.object(),
        customcode: yup.string(),
    })
    .required()

export const schema = [
    schema1,
    schema2,
    schema3
]