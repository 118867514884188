import jwt_decode from "jwt-decode";
import moment from "moment";


export const checkIfValid = (accessToken, username) => {
    const decoded = accessToken !== "" ? jwt_decode(accessToken) : ""
    if (decoded && username) {
        if (decoded.data.username === username) return true
        return false
    }
    return false
}

export const errorFinder = (value) => value === undefined ? false : true

export const dateFormateChanger = (date) => {

    const formatedDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD hh:mm:ss") !== "Invalid date"
        ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD hh:mm:ss") : moment(date).format("YYYY-MM-DD hh:mm:ss")

    if (formatedDate !== "Invalid date") {
        return formatedDate
    }
    return ""
}

export const UIDateFormat = (date) => {
    const formatedDate = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY") //removed hh:mm:ss 

    if (formatedDate !== "Invalid date") {
        return formatedDate
    }
    return null
}

export const UIDateFormatWT = (date) => {
    const formatedDate = moment(date, "YYYY-MM-DD hh:mm:ss").format("MMM DD, YYYY hh:mm a") //removed hh:mm:ss 

    if (formatedDate !== "Invalid date") {
        return formatedDate
    }
    return null
}

export const parseBoolean = (value) => value === 1 ? "Yes" : value === 0 ? "No" : value

export const isDateNotProvided = (date) => date === "0000-00-00 00:00:00" ? true : false

export const isDateProvided = (date) => date !== "0000-00-00 00:00:00" ? true : false

export const parseBits = (value) => value === 1 ? true : value === 0 ? false : value

