import { Container } from "@mui/material";
import bgImage from "assets/images/bg-desktop.jpg";
import HomePageLayout from "layouts/components/HomePageLayout";
import { demoMainContent } from "static/homepage/data";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

export const Demo = () => {

    const { topic, content, image } = demoMainContent
    return (
        <HomePageLayout
            bgColor={"inherit"}
            image={bgImage}
            pageName="Demo"
            coverHeight=""
            heroText=""
        >

            <Container>

                <Grid container className='mt-12'>

                    <Grid item xs={12} md={6}>
                        <MDBox>
                            <MDTypography sx={{ fontSize: "2rem" }} className="text-center">
                                {topic}
                            </MDTypography>

                            <MDTypography sx={{ marginTop: "3rem" }} className="text-justify justify-center">
                                <a href="https://mybreview.com/webglitsreview">Click Here To View Demo</a>
                            </MDTypography>
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MDBox className="xs:ml-0">
                            <img className='object-cover m-4 w-full h-[600px]' src={image.src} alt={image.alt} />
                        </MDBox>
                    </Grid>

                </Grid>
            </Container>


        </HomePageLayout>
    );
}

