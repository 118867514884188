import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

function DefaultNavbarMobile({ open, close }) {

  var light = true;

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
    >
      <MDBox className="mobileMenu">

        <DefaultNavbarLink icon="home" name="Home" route="/" light={light} className="mobile-link" />

        <DefaultNavbarLink icon="" name="About" route="/about" light={light} className="mobile-link" />

        <DefaultNavbarLink icon="" name="Features" route="/features" light={light} className="mobile-link" />

        <DefaultNavbarLink icon="" name="Demo" route="/demo" light={light} className="mobile-link" />

        <DefaultNavbarLink icon="" name="Pricing" route="/pricing" light={light} className="mobile-link" />

        <DefaultNavbarLink icon="" name="Contact" route="/contact" light={light} className="mobile-link" />

        <DefaultNavbarLink
          className="redButton"
          icon=""
          name="Register"
          route="/sign-up"
          light={light}
        />

        <DefaultNavbarLink
          className="redButton"
          icon=""
          name="Log In"
          route="/sign-in"
          light={light}
        />
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
