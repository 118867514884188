import { useQuery } from "react-query"
import axios from "axios"
import { baseApiUrl } from "services/baseUrl"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"


export const useFetch = (queryKey, url, onSuccess, onError, enable, select) => {

    const accessToken = useSelector((state) => state.globalState.accessToken)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return useQuery(
        [queryKey],
        async () => {
            return await axios({
                method: "GET",
                url: `${baseApiUrl}${url}`,
                headers: {
                    'AUTHORIZATION': `Bearer ${accessToken}`
                }
            })
                .then((res) => {
                    if (res.data.status === 401) {
                        dispatch({ type: "globalState/setLogOut" })
                        navigate("/sign-in")
                        return
                    }
                    return res.data
                })
                .catch((err) => {
                    console.log('err:', err)
                    if (err.response.status === 401) {

                        dispatch({ type: "globalState/setLogOut" })
                        dispatch({ type: "globalState/setAlertMessage", payload: { type: "error", message: "Session Expired!" } })
                        navigate("/sign-in")
                        return
                    }
                    return err.response
                }
                )
        },
        {
            onSuccess,
            onError,
            // cacheTime: 30000,
            enabled: enable,
            select: select,
            refetchOnWindowFocus: false,
        })
}