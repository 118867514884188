
// @mui icons
import Icon from "@mui/material/Icon";
import { HomePage } from "layouts/homePage";
import { SignUp } from "layouts/sign-up";
import { SignIn } from "layouts/sign-in";
import { ResetPassword } from "layouts/reset-password";
import { About } from "layouts/homePage/About";
import { Feature } from "layouts/homePage/Feature";
import { Demo } from "layouts/homePage/Demo";
import { Pricing } from "layouts/homePage/Pricing";
import { Contact } from "layouts/homePage/Contact";
import { TermsOfService } from "layouts/homePage/TermsOfService";

const routes = [
  {
    type: "collapse",
    name: "Home Page",
    key: "home-page-index",
    icon: <Icon fontSize="small">Home Page</Icon>,
    route: "/",
    component: <HomePage />,
  },
  {
    type: "collapse",
    name: "About",
    key: "about",
    icon: <Icon fontSize="small">About</Icon>,
    route: "/about",
    component: <About />,
  },
  {
    type: "collapse",
    name: "Home Page",
    key: "features",
    icon: <Icon fontSize="small">Features</Icon>,
    route: "/features",
    component: <Feature />,
  },
  {
    type: "collapse",
    name: "Demo",
    key: "demo",
    icon: <Icon fontSize="small">Demo</Icon>,
    route: "/demo",
    component: <Demo />,
  },
  {
    type: "collapse",
    name: "Terms Of Service",
    key: "termsOfService",
    icon: <Icon fontSize="small">Terms of service</Icon>,
    route: "/termsOfService",
    component: <TermsOfService />,
  },
  {
    type: "collapse",
    name: "Pricing",
    key: "pricing",
    icon: <Icon fontSize="small">Pricing</Icon>,
    route: "/pricing",
    component: <Pricing />,
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">Contact</Icon>,
    route: "/contact",
    component: <Contact />,
  },
  {
    type: "collapse",
    name: "Sign up",
    key: "sign-up",
    icon: <Icon fontSize="small">Sign Up</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">Sign-in</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">Reset Password</Icon>,
    route: "/reset-password",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Home Page",
    key: "home-page",
    icon: <Icon fontSize="small">Home Page</Icon>,
    route: "*",
    component: <HomePage />,
  },

];

export default routes;
