// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CloseIcon from '@mui/icons-material/Close';

// Images
import bgImage from "assets/images/slider.jpg";
import { Alert, CircularProgress, Collapse, FormHelperText, IconButton } from "@mui/material";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { useMutate } from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "redux/globalState";
import OTPInput, { ResendOTP } from "otp-input-react";
import BasicLayout from "layouts/components/BasicLayout";
// import { useMaterialUIController } from "context";
import { useWindowSize } from "react-use";
import { errorFinder } from "utilities/commonFunctions";

const initialFormData = {
  username: "",
  mobile: "",
  password: ""
}

const normalizeNumber = (value) => {
  return value.replace(/[^0-9]/g, '');
}

const schema = yup
  .object()
  .shape({
    username: yup.string().max(30).required("Full Name is Required"),
    mobile: yup.string().min(10).max(12).required("Mobile Number is Required"),
    password: yup.string().max(30).required("Password is Required"),
  })
  .required();


export const SignUp = () => {

  const initialErrorAlertState = { message: "", state: false }
  const [errorAlert, setErrorAlert] = useState(initialErrorAlertState);
  const [otpVerification, setOtpVerification] = useState(false)

  const [OTP, setOTP] = useState("");

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormData,
    shouldUnregister: false,
  });

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSuccess = async (response) => {
    //handle success
    console.log("sign-up Response", response);
    if (response.status) {
      setOtpVerification(true)
      dispatch(setAlertMessage({ type: "success", message: response.message }))
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }

  const onError = async (error) => {
    console.log('Sign-up Error', error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    if (error.status === 401) {
      navigate("/sign-in")
    }
  }

  const { mutate, isLoading } = useMutate("sign-up", `/signup.php`, onSuccess, onError)


  const onOTPSuccess = async (response) => {
    //handle success
    console.log("sign-up Response", response);
    if (response.status) {
      navigate("/sign-in")
      dispatch(setAlertMessage({ type: "success", message: response.message }))
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }

  const onOTPErro = async (error) => {
    console.log('Sign-up Error', error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error);
  }

  const { mutate: mutateOtp, isLoading: isOtpLoading } = useMutate("otp-verification", `/verifyotp.php`, onOTPSuccess, onOTPErro)

  const submit = (e) => {
    // console.log('submit  e', e)

    let bodyFormData = new FormData()

    bodyFormData.append("username", e.username)
    bodyFormData.append("mobile", e.mobile)
    bodyFormData.append("password", e.password)

    mutate(bodyFormData)
  }


  const handleOTPSubmit = (e) => {
    e.preventDefault()

    let bodyFormData = new FormData()
    bodyFormData.append("otp", OTP)
    bodyFormData.append("mobile", getValues().mobile)

    if (OTP.length === 4) {
      mutateOtp(bodyFormData)
    } else {
      setErrorAlert({ message: "Enter the 4-digit OTP", state: true })
    }
  }


  return (
    <BasicLayout coverHeight="100vh" image={bgImage}>
      <Card >
        <MDBox
          bgColor="inherit"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            Register today
          </MDTypography>
          <MDTypography display="block" variant="button" color="dark" my={1}>
            Enter your Mobile and password to register
          </MDTypography>
        </MDBox>

        <MDBox width={"100%"}>
          <Collapse sx={{ marginBottom: "-25px" }} in={errorAlert.state}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(initialErrorAlertState);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>


          {otpVerification ?
            <MDBox component="form" role="form" onSubmit={handleOTPSubmit}>
              {/* //?OTP Form */}
              <MDTypography
                display="flex"
                justifyContent="center"
                sx={{ fontSize: "1rem", paddingBottom: "1rem" }}
              >
                Enter the OTP send to your Registered Mobile
              </MDTypography>

              <MDBox className="flex justify-center items-center" mb={2}>
                <OTPInput
                  name="otp"
                  inputStyles={{
                    marginRight: "20px", marginLeft: "20px",
                    border: "1px solid black",
                    borderRadius: ".25rem"
                  }}
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                // secure
                />
                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
              </MDBox>

              <MDTypography sx={{ fontSize: "1rem", textAlign: "center" }}>
                Contact us if you didn't get OTP <br /><a style={{ color: "#1a73e8" }} href="tel:+919043022255">+91 9043022255</a>
              </MDTypography>

              <MDBox mt={1} mb={1} sx={{ textAlign: "center" }}>
                <MDButton type="submit" variant="gradient" color="primary">
                  {isOtpLoading ? <CircularProgress color="inherit" /> : "Verify"}
                </MDButton>
              </MDBox>
            </MDBox>
            :
            <MDBox component="form" role="form" onSubmit={handleSubmit(submit)}>{/* //?Register Form */}
              <MDBox mb={2}>
                <MDInput
                  error={errorFinder(errors.username)}
                  type="text"
                  label="Full Name"
                  // variant="standard"
                  {...register("username")}
                  fullWidth />
                {errors.username && <FormHelperText error id="username-helper-text">{errors.username?.message}</FormHelperText>}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Mobile"
                  error={errorFinder(errors.mobile)}
                  // variant="standard"
                  {...register("mobile", {
                    onChange: e => setValue("mobile", normalizeNumber(e.target.value))
                  })}
                  fullWidth />
                {errors.mobile && <FormHelperText error id="mobile-helper-text">{errors.mobile?.message}</FormHelperText>}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  error={errorFinder(errors.password)}
                  // variant="standard"
                  {...register("password")}
                  fullWidth />
                {errors.password && <FormHelperText error id="password-helper-text">{errors.password?.message}</FormHelperText>}
              </MDBox>
              <MDBox mt={4} mb={1} sx={{ textAlign: "center" }}>
                <MDButton sx={{ fontSize: "1.1rem" }} type="submit" variant="gradient" color="primary">
                  {isLoading ? <CircularProgress color="inherit" /> : "Register"}
                </MDButton>
              </MDBox>
            </MDBox>
          }

          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/sign-in"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>

          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Forgot Password?
            </MDTypography>
          </MDBox>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}
