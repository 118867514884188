import React, { useEffect, useMemo, useState } from 'react'
import { Step, StepLabel, Stepper, styled } from '@mui/material';
import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import colors from "assets/theme/base/colors";

import { schema } from "./schema"
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorage } from 'react-use';


import BasicDetailsForm from './BasicDetailsForm';
import LinksDetailsForm from './LinksDetailsForm';
import ThemeDetailsForm from './ThemeDetailsForm';

import { setAlertMessage } from "redux/globalState"
import { useDispatch } from 'react-redux';
import { useMutate } from "hooks/useMutate"
import { queryClient } from 'index';
import { useNavigate, useParams } from 'react-router-dom';
import PreloadFonts from './PreloadFonts';
import { Check } from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { planOptions, themeIdOptions } from 'static/homepage/data';
import MDTypography from 'components/MDTypography';

const isLastFieldDirtied = (field) => {

    const result = (
        (field.linktitle === "")
        || (field.linkurl === "")
        || (field.linkicon === null)
    )
    return !result
}

const initialDataFields = {
    plan: 1, // (integer field)
    id: "",
    pageurl: "",
    pagetitle: "",
    description: "",
    logoimg: "",
    coverimg: "",
    links: [{
        linktitle: "",
        linkurl: "",
        linkicon: "",
        linkdescription: "",
    }],
    socials: [{
        linktitle: "",
        linkurl: "",
        linkicon: "",
        linkdescription: "",
    }],
    themeid: "",
    themedetails: JSON.stringify({
        color1: "#00ff6a",
        color2: "#00d4ff",
        font1: "",
        font2: "",
        footercolor: "#9500ff",
        background1: "#ffda0a",
        background2: "#ff0a0a",
    }, null, 4),
    customcode: "",
}
console.log("initialDataFields-->", initialDataFields);

const NewPage = () => {

    const [dataFields, setDataFields, removeFields] = useLocalStorage("reviewFormFields", initialDataFields)
    console.log("NewPage  dataFields-->", dataFields);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    //* Getting Page URL
    const { url } = useParams()
    const [controller,] = useMaterialUIController()

    const {
        darkMode,
    } = controller;


    const QontoStepIconRoot =
        styled('div')(({ theme, ownerState }) => ({
            color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#7c7ca2',
            display: 'flex',
            height: 22,
            alignItems: 'center',
            ...(ownerState.active && {
                color: theme.palette.primary.main,
            }),
            '& .QontoStepIcon-completedIcon': {
                color: theme.palette.primary.main,
                zIndex: 1,
                fontSize: 18,
            },
            '& .QontoStepIcon-circle': {
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: 'currentColor',
            },
        }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    const QontoConnector = useMemo(() => {
        return styled(StepConnector)(({ theme }) => ({
            [`&.${stepConnectorClasses.alternativeLabel}`]: {
                top: 10,
                left: 'calc(-50% + 16px)',
                right: 'calc(50% + 16px)',
            },
            [`&.${stepConnectorClasses.active}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                    borderColor: colors[controller.sidenavColor].main,
                },
            },
            [`&.${stepConnectorClasses.completed}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                    borderColor: colors[controller.sidenavColor].main,
                },
            },
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#a3a3bd',
                borderTopWidth: 3,
                borderRadius: 1,
            },
        }));
    }, [controller.sidenavColor])


    const [activeStep, setActiveStep] = useState(parseInt(JSON.parse(localStorage.getItem("currentStep"))) || 0);

    useEffect(() => {
        localStorage.setItem("currentStep", activeStep)
    }, [activeStep])

    const customSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "100",
            backgroundColor: "transparent",
            // borderColor: state.isFocused ? 'grey' : 'red',
            height: "43.5px",
            fontSize: ".9rem",
            // color: darkMode ? "white" : "black",
            fontWeight: "400",
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: ".9rem"
        }),
        singleValue: provided => ({
            ...provided,
            color: darkMode ? "white" : "black",
            fontWeight: "400"
        }),
        option: provided => ({
            ...provided,
            zIndex: 9999,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: base => ({ ...base, zIndex: 9999, minWidth: "210px" }),
        input: provided => ({
            ...provided,
            color: darkMode ? "white" : "black",
            fontWeight: "400",
            width: "100px",
            ":after": {
                ...provided[':after'],
                color: darkMode ? "white" : "black",
                fontWeight: "400",
            }
        })
    }

    const updateFieldData = async ({ ...page }) => { //? why I gave like {...page} is bcoz it creates a new reference for the page variable 
        //? otherwise the variable value is passed to the initial variable somehow. (pass by reference) 

        if (page) {
            page["plan"] = planOptions[page.plan - 1]
            page["links"] = JSON.parse(page.links)
            page["socials"] = JSON.parse(page.socials)
            page["themeid"] = themeIdOptions[page.themeid - 1]
            page["themedetails"] = page.themedetails

            //Basic Details
            setValue("pageurl", page.pageurl)
            setValue("pagetitle", page.pagetitle)
            setValue("plan", page.plan)
            setValue("coverimg", page.coverimg)
            setValue("logoimg", page.logoimg)
            setValue("description", page.description)

            //Links
            setValue1("links", page.links)
            setValue1("socials", page.socials)

            //Theme Details
            setValue2("pageurl", page.pageurl)
            setValue2("pagetitle", page.pagetitle)
            setValue2("plan", page.plan)
            setValue2("coverimg", page.coverimg)
            setValue2("logoimg", page.logoimg)
            setValue2("description", page.description)
            setValue2("links", page.links)
            setValue2("socials", page.socials)
            setValue2("themeid", page.themeid)
            setValue2("themedetails", page.themedetails)
            setValue2("customcode", page.customcode)

            trigger()
            trigger1()
            trigger2()

            setDataFields(page)
            reset(page)
            reset1({ links: page.links, socials: page.socials })
            reset2(page)

        } else {
            navigate(-1)
            setDataFields(initialDataFields)
            reset(initialDataFields)
        }
    }

    useEffect(() => {
        if (url !== undefined) {
            handleReset()
            const pageDetails = queryClient.getQueryData([["viewpage", url]])

            let page = pageDetails?.page

            if (page) {
                updateFieldData(page)
            } else {
                navigate(-1)
                setDataFields(initialDataFields)
                reset(initialDataFields)
            }
        }

        return () => {
            if (url !== undefined) handleReset()
        }

    }, [url])

    const basicDetailsFields = {
        plan: dataFields.plan,
        pageurl: dataFields.pageurl,
        pagetitle: dataFields.pagetitle,
        description: dataFields.description,
        id: dataFields.id,
    }

    const linksDetailsFields = {
        links: dataFields.links,
        socials: dataFields.socials,
    }


    const themeDetailsFields = {
        themeid: dataFields.themeid,
        themedetails: dataFields.themedetails,
        customcode: dataFields.customcode,
    }

    const { register, reset, handleSubmit, watch, formState: { errors, isValid }, trigger, setValue, setError, clearErrors } = useForm({
        resolver: yupResolver(schema[0]),
        defaultValues: basicDetailsFields,
        shouldUnregister: false,
    });

    const { register: register1, reset: reset1, handleSubmit: handleSubmit1, watch: watch1,
        formState:
        {
            errors: errors1,
            isValid: isValid1,
        },
        control,
        trigger: trigger1,
        setValue: setValue1
    } = useForm({
        defaultValues: linksDetailsFields,
        resolver: yupResolver(schema[1]),
        shouldUnregister: false,
    });

    const { register: register2, reset: reset2, handleSubmit: handleSubmit2, watch: watch2,
        formState: {
            errors: errors2,
            isValid: isValid2,
        },
        trigger: trigger2,
        setValue: setValue2
    } = useForm({
        defaultValues: themeDetailsFields,
        resolver: yupResolver(schema[2]),
        shouldUnregister: false,
    });

    const { fields: linkFields, append: appendLink, remove: removeLink } = useFieldArray({
        control: control,
        name: "links",
        keyName: "id",
        shouldUnregister: false,
    });

    const { fields: socialFields, append: appendSocial, remove: removeSocial } = useFieldArray({
        control: control,
        name: "socials",
        keyName: "id",
        shouldUnregister: false,
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log(value, name, type)
            setDataFields((prev) => {
                return ({ ...dataFields, ...value })
            })
        });
        return () => {
            subscription.unsubscribe()
        };

    }, [watch, setDataFields, dataFields]);

    useEffect(() => {
        const subscription = watch1((value, { name, type }) => {
            console.log(value, name, type)
            setDataFields((prev) => {
                return ({ ...dataFields, ...value })
            })
        });

        return () => {
            subscription.unsubscribe()
        };

    }, [watch1, setDataFields, dataFields]);

    useEffect(() => {
        const subscription = watch2((value, { name, type }) => {
            console.log(value, name, type)
            setDataFields((prev) => {
                return ({ ...dataFields, ...value })
            })
        });

        return () => {
            subscription.unsubscribe()
        };

    }, [watch2, setDataFields, dataFields]);


    const onSuccess = (response) => {
        console.log("submittions Response", response)
        dispatch(setAlertMessage({ type: response.status ? "success" : "error", message: response.message }))
        response.status && handleReset()
        response.status && navigate("/pages")
        queryClient.invalidateQueries(["cart"])
    }

    const onError = (error) => {
        console.log("submittions Response", error)
        dispatch(setAlertMessage({ type: "error", message: error?.message }))
        if (error.status === 401) {
            navigate("/sign-in")
        }
    }

    const { mutate } = useMutate("create-page", "/create-page.php", onSuccess, onError)

    const postToApi = (e) => {
        // data Treatment and processing
        let data = {
            pageid: dataFields.id,
            plan: 1,
            pageurl: dataFields.pageurl,
            pagetitle: dataFields.pagetitle,
            logoimg: dataFields.logoimg,
            coverimg: dataFields.coverimg,
            description: dataFields.description,
            links: JSON.stringify(dataFields.links),
            socials: JSON.stringify(dataFields.socials),
            themeid: dataFields.themeid.id,
            themedetails: dataFields.themedetails,
            customcode: dataFields.customcode,
        }
        console.log('onSubmit  data', data)

        mutate(data)
    }

    const handleNewLinks = () => {
        const lastLinkIndex = dataFields.links.length - 1
        isLastFieldDirtied(dataFields.links[lastLinkIndex]) === true ?
            appendLink({ linktitle: "", linkurl: "", linkicon: "" }) : dispatch(setAlertMessage({ type: "error", message: "Please Fill the Link Fields" }))
    }

    const handleNewSocials = () => {
        const lastSocialIndex = dataFields.socials.length - 1
        isLastFieldDirtied(dataFields.socials[lastSocialIndex]) === true ?
            appendSocial({ linktitle: "", linkurl: "", linkicon: "" }) : dispatch(setAlertMessage({ type: "error", message: "Please Fill the Social Fields" }))
    }

    const handleNext = () => {
        setActiveStep((prev) => prev + 1)
        activeStep === 0 && reset1(linksDetailsFields)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        reset({
            plan: initialDataFields.plan, // (integer field)
            id: initialDataFields.id,
            pageurl: initialDataFields.pageurl,
            pagetitle: initialDataFields.pagetitle,
            description: initialDataFields.description,
            logoimg: initialDataFields.logoimg,
            coverimg: initialDataFields.coverimg,
        })
        reset1({ links: initialDataFields.links, socials: initialDataFields.socials })
        reset2({
            themeid: initialDataFields.themeid,
            themedetails: initialDataFields.themedetails,
            customcode: initialDataFields.customcode,
        })
        localStorage.removeItem("currentStep")
        setActiveStep(0)
        setDataFields(initialDataFields)
    }

    const updateFields = (fields) => {
        setDataFields((prevFields) => {
            return { ...prevFields, ...fields }
        })
    }

    const handleNavigateSteps = (step) => {
        if (step === 0) {
            setActiveStep(step)
            return
        }
        if (step === 1) {
            if (isValid) {
                setActiveStep(step)
            } else {
                dispatch(setAlertMessage({ type: "error", message: "Please Fill the Basic Details" }))
            }
            return
        }
        if (step === 2) {
            if (isValid1) {
                setActiveStep(step)
            } else {
                dispatch(setAlertMessage({ type: "error", message: !isValid ? "Please Fill the Basic Details" : "Please Fill the Link Details" }))
            }
            return
        }
    }

    const isAllValid = () => {
        return isValid && isValid1 && isValid2
    }

    const steps = [
        {
            label: 'Basic Details',
            form: <BasicDetailsForm {...dataFields} register={register} handleSubmit={handleSubmit} url={url} errors={errors} setError={setError}
                clearErrors={clearErrors} isValid={isValid} isAllValid={isAllValid} setValue={setValue} customSelectStyles={customSelectStyles} trigger={trigger} darkMode={darkMode}
                updateFields={updateFields} activeStep={activeStep} handleReset={handleReset} handleNext={handleNext} postToApi={postToApi} />
        },
        {
            label: 'Links Details',
            form: <LinksDetailsForm  {...dataFields} control={control} linkFields={linkFields} socialFields={socialFields} url={url} removeLink={removeLink}
                removeSocial={removeSocial} handleNewLinks={handleNewLinks} handleNewSocials={handleNewSocials} customSelectStyles={customSelectStyles}
                register={register1} handleSubmit={handleSubmit1} updateFields={updateFields} trigger={trigger1} setValue={setValue1} postToApi={postToApi}
                errors={errors1} isValid={isValid1} isAllValid={isAllValid} activeStep={activeStep} handleReset={handleReset} handleNext={handleNext} handleBack={handleBack} />
        },
        {
            label: 'Theme Details',
            form: <ThemeDetailsForm {...dataFields} register={register2} handleSubmit={handleSubmit2} url={url} updateFields={updateFields}
                errors={errors2} isValid={isValid2} activeStep={activeStep} handleBack={handleBack} setValue={setValue2} trigger={trigger2}
                customSelectStyles={customSelectStyles} handleReset={handleReset} postToApi={postToApi} />
        },
    ];

    const handleCompleted = (index) => {
        if (index === 0) {
            return isValid
        }
        if (index === 1) {
            return isValid1
        }
        if (index === 2) {
            return isValid2
        }
    }


    return (

        <MDBox width="100%">
            <PreloadFonts></PreloadFonts>
            <MDTypography variant="h3" mb={2} color="dark" >{dataFields.id === "" ? "Create New Page" : `Edit Page: ${dataFields.pagetitle} `}</MDTypography>
            <Stepper alternativeLabel sx={{ background: darkMode ? "inherit" : "white", color: "black", boxShadow: "none", border: "1px solid #ccc" }}
                activeStep={activeStep} connector={<QontoConnector />} >
                {steps.map((step, index) => {
                    return (
                        <Step key={step.label}>
                            <StepLabel StepIconComponent={QontoStepIcon} StepIconProps={{ completed: handleCompleted(index) }}>
                                <MDTypography
                                    onClick={() => handleNavigateSteps(index)}
                                    sx={
                                        (theme) => {
                                            return {
                                                color: "black", fontWeight: "bold",
                                                fontSize: ".9rem", cursor: "pointer",
                                                "&:hover": { color: theme.palette.primary.main },
                                            }
                                        }
                                    }
                                >{step.label}</MDTypography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {/* Implement Form Data in here */}
            <MDBox sx={{
                marginTop: "20px",
                marginBottom: "40px",
                position: "relative",
                background: darkMode ? "inherit" : "white",
                padding: "20px",
                borderRadius: ".5rem",
                border: "1px solid #ccc"
            }}>

                {(steps[activeStep]).form}

            </MDBox>



        </MDBox>

    )
}


export default NewPage