import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { errorFinder } from 'utilities/commonFunctions';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { useImgMutate } from 'hooks/useImgMutate';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutate } from 'hooks/useMutate';
import { useSessionStorage } from 'react-use';


const BasicDetailsForm = ({ id, plan, url = false, postToApi, clearErrors, pagetitle, pageurl, setError, logoimg, coverimg, handleReset, customSelectStyles, setValue, trigger, activeStep, handleNext, handleSubmit, register, errors, isValid, isAllValid }) => {

    const [pageExistUrl, setPageExistUrl] = useSessionStorage(
        "pageExistUrl",
        ""
    )
    useEffect(() => {
        setPageExistUrl("")
        return () => {
            setPageExistUrl("")
        }
    }, [])

    const [isPageExist, setIsPageExist] = useState(true)
    const [lastCheckedUrl, setLastCheckedUrl] = useState("")

    const onSubmit = (e) => {
        console.log('onSubmit  e', e)

        if (pageExistUrl === "") {
            checkIfPageExist("onSubmit")
            return
        }

        if (pageExistUrl === pageurl && id === "") {
            setError("pageurl", { type: "manual", message: "Page URL already exist" })
            return
        }

        isValid && handleNext()

    }

    const [focusedPageUrl, setFocusedPageUrl] = useState(false)

    const coverImgRef = useRef(null)

    const logoImgRef = useRef(null)

    const logoImgPresent = logoimg

    const coverImgPresent = coverimg

    const { mutate: mutateCoverImgUpload } = useImgMutate("coverImgUpload", "/api-upload-image-aws.php",
        (res) => {
            if (res.status) {
                setValue(
                    "coverimg", res.imgurl
                )
            } else {

            }
        },
        (err) => {
            console.log("coverImg upload error", err)
        }
    )

    const { mutate: mutateLogoImgUpload } = useImgMutate("logoImgUpload", "/api-upload-image-aws.php",
        (res) => {
            if (res.status) {
                setValue(
                    "logoimg", res.imgurl
                )
            } else {

            }
        },
        (err) => {
            console.log("logoImg upload error", err)
        }
    )

    const imageUpload = (file, name) => {

        if (name === "coverimg") {
            mutateCoverImgUpload(file)
        }

        if (name === "logoimg") {
            mutateLogoImgUpload(file)
        }
    }

    const onPageURLSuccess = (res) => {
        console.log('onPageURLSuccess  res:', res)
        if (res.status) {
            setValue(
                "pageurl", res.pageurl
            )
            trigger("pageurl")
        } else {

        }
    }

    const onPageURLError = (err) => {
        console.log("onPageURLError", err)
    }

    const { mutate: mutatePageURL } = useMutate("pageURL", "/get-page-url.php", onPageURLSuccess, onPageURLError)

    const onPageExistSuccess = (res) => {
        console.log('onPageExistSuccess  res:', res)
        setLastCheckedUrl(pageurl)
        if (res.status) {
            if (res.isPageExist) {
                setError("pageurl", { type: "manual", message: "Page URL already exist" })
                setPageExistUrl(pageurl)
                setIsPageExist(true)
            } else {
                setPageExistUrl("")
                clearErrors("pageurl")
                setIsPageExist(false)
            }
        } else {

        }
    }

    const onPageExistError = (err) => {
        console.log("onPageExistError", err)
    }

    const { mutate: mutatePageExist } = useMutate("isPageExist", "/check-page-exist.php", onPageExistSuccess, onPageExistError)

    const getPageURL = () => {
        if (pagetitle === "") return
        if (pageurl !== "") return
        if (id !== "") return
        mutatePageURL({ pagetitle: pagetitle })
    }

    const checkIfPageExist = (type) => {
        if (pageurl === "") return
        if (id !== "") {
            isValid && handleNext()
            return
        }

        mutatePageExist({ pageurl: pageurl },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        if (res.isPageExist) {
                        } else {
                            if (type === "onSubmit") {
                                pageExistUrl === "" && isValid && handleNext()
                                return
                            }
                        }
                    }
                }
            }
        )

        if (type === "onSubmit") {
            !isPageExist && pageExistUrl === "" && isValid && handleNext()
        }
    }

    const isAllFormValid = isAllValid()

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6}>

                <Grid item xs={12} sm={6} className="px-2">
                    <FormControl
                        onBlur={() => {
                            getPageURL()
                        }}
                        variant="outlined" sx={{ width: "100%" }}>
                        <MDInput
                            type="text"
                            label="Pagetitle *"
                            error={errorFinder(errors.pagetitle)}
                            id="pagetitle" {...register("pagetitle", {
                            })} />
                        {errors.pagetitle && <FormHelperText error id="pagetitle-helper-text">{errors.pagetitle?.message}</FormHelperText>}
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6} className="px-2">
                    <FormControl onFocus={() => setFocusedPageUrl(true)}
                        onBlur={() => {
                            setFocusedPageUrl(false)
                            errors.pageurl?.type !== "manual" && (lastCheckedUrl !== pageurl && id === "") && checkIfPageExist()
                        }}
                        variant="outlined" sx={{ width: "100%" }} >
                        <MDInput
                            InputLabelProps={{ shrink: focusedPageUrl || pageurl !== "" }}
                            disabled={id !== ""}
                            className={`${id !== "" && "opacity-50 border rounded-md"}`}
                            label={"Page Url *"}
                            error={errorFinder(errors.pageurl)}
                            id="pageurl" {...register("pageurl", {


                            })} />
                        {errors.pageurl && <FormHelperText error id="pageurl-helper-text">{errors.pageurl?.message}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* //* Image Upload */}

                <Grid item xs={12} sm={logoImgPresent ? 4 : 6} className="px-4">
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                        Logo Image  <input
                            className='border rounded-none'
                            ref={logoImgRef}
                            type="file"
                            // label="Logo Image *"
                            onChange={(e) => {
                                imageUpload(e.target.files[0], "logoimg")
                            }}
                            name="logoimg"
                            id="logoimg"
                        />
                    </FormControl>
                </Grid>

                {/* // * Logo Image Preview */}
                {logoImgPresent && <Grid item sm={2} sx={{ position: "relative" }}>
                    <DeleteIcon sx={{ color: "red", position: "absolute", right: 2, cursor: "pointer" }} onClick={() => {
                        setValue("logoimg", "")
                        logoImgRef.current.value = null
                    }} />
                    <img src={logoimg} alt={"logoImgPrev"} />
                </Grid>}

                <Grid item xs={12} sm={coverImgPresent ? 4 : 6} className="px-4">
                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                        Cover Image  <input
                            className='border rounded-none'
                            ref={coverImgRef}
                            type="file"
                            // label="Cover Image *"
                            name="coverimg"
                            onChange={(e) => {
                                imageUpload(e.target.files[0], "coverimg")
                            }}
                            id="coverimg" />
                    </FormControl>
                </Grid>

                {/* // * Cover Image Preview */}
                {coverImgPresent && <Grid item sm={2} sx={{ position: "relative" }} >
                    <DeleteIcon sx={{ color: "red", position: "absolute", right: 2, cursor: "pointer" }} onClick={() => {
                        setValue("coverimg", "")
                        coverImgRef.current.value = null
                    }} />
                    <img src={coverimg} alt={"coverImgPrev"} />
                </Grid>}

                <Grid item xs={12} className="px-4">
                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                        <MDInput
                            label="Description"
                            multiline
                            minRows={4}
                            // style={{ padding: "4px", border: "none" }}
                            id="description" {...register("description", {

                            })} />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item xs={12} className="px-4">
                <MDTypography sx={{ mt: 2 }}>Step {activeStep + 1}</MDTypography>
                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 1, gap: "20px" }}>

                    {!url && <MDButton type="button" color="warning" onClick={() => handleReset()}>
                        Reset
                    </MDButton>}

                    <MDBox sx={{ flex: '1 1 auto' }} />

                    <MDButton sx={(theme) => { return { backgroundColor: theme.palette.primary.main } }} color="dark" type="submit">
                        Next
                    </MDButton>

                    {(url || isAllFormValid) && <MDButton onClick={postToApi} sx={(theme) => { return { backgroundColor: theme.palette.primary.main } }} color="dark" >
                        Submit
                    </MDButton>}
                </MDBox>
            </Grid>

        </form>

    )
}

export default BasicDetailsForm