import React from 'react'
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { errorFinder } from 'utilities/commonFunctions';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import DeleteIcon from '@mui/icons-material/Delete';
import CreatableSelect from 'react-select/creatable';
import { baseUrlServer } from 'services/baseUrl';
import { components } from "react-select"

const CustomOption = [
    { icon: `${baseUrlServer}/images/google.png`, label: "google", id: "1", value: "1" },
    { icon: `${baseUrlServer}/images/facebook.png`, label: "facebook", id: "6", value: "6" },
    { icon: `${baseUrlServer}/images/twitter.png`, label: "twitter", id: "2", value: "2" },
    { icon: `${baseUrlServer}/images/instagram.png`, label: "instagram", id: "3", value: "3" },
    { icon: `${baseUrlServer}/images/linkedin.png`, label: "linkedin", id: "10", value: "10" },
    { icon: `${baseUrlServer}/images/youtube.png`, label: "youtube", id: "26", value: "26" },
    { icon: `${baseUrlServer}/images/whatsapp.png`, label: "whatsapp", id: "25", value: "25" },
    { icon: `${baseUrlServer}/images/behance.png`, label: "behance", id: "4", value: "4" },
    { icon: `${baseUrlServer}/images/justdial.png`, label: "justdial", id: "28", value: "28" },
    { icon: `${baseUrlServer}/images/website.png`, label: "website", id: "29", value: "29" },
    { icon: `${baseUrlServer}/images/dribble.png`, label: "dribble", id: "5", value: "5" },
    { icon: `${baseUrlServer}/images/flickr.png`, label: "flickr", id: "7", value: "7" },
    { icon: `${baseUrlServer}/images/line.png`, label: "line", id: "9", value: "9" },
    { icon: `${baseUrlServer}/images/messenger.png`, label: "messenger", id: "11", value: "11" },
    { icon: `${baseUrlServer}/images/pinterest.png`, label: "pinterest", id: "12", value: "12" },
    { icon: `${baseUrlServer}/images/reddit.png`, label: "reddit", id: "13", value: "13" },
    { icon: `${baseUrlServer}/images/skype.png`, label: "skype", id: "14", value: "14" },
    { icon: `${baseUrlServer}/images/snapchat.png`, label: "snapchat", id: "15", value: "15" },
    { icon: `${baseUrlServer}/images/sound_cloud.png`, label: "sound cloud", id: "16", value: "16" },
    { icon: `${baseUrlServer}/images/spotify.png`, label: "spotify", id: "17", value: "17" },
    { icon: `${baseUrlServer}/images/telegram.png`, label: "telegram", id: "18", value: "18" },
    { icon: `${baseUrlServer}/images/tik_tok.png`, label: "tik tok", id: "19", value: "19" },
    { icon: `${baseUrlServer}/images/tinder.png`, label: "tinder", id: "20", value: "20" },
    { icon: `${baseUrlServer}/images/tumblr.png`, label: "tumblr", id: "21", value: "21" },
    { icon: `${baseUrlServer}/images/twitch.png`, label: "twitch", id: "22", value: "22" },
    { icon: `${baseUrlServer}/images/vimeo.png`, label: "vimeo", id: "23", value: "23" },
    { icon: `${baseUrlServer}/images/wechat.png`, label: "wechat", id: "24", value: "24" },
    { icon: `${baseUrlServer}/images/others.png`, label: "other", id: "27", value: "27" },
]

const Option = ({ children, ...props }) => {
    return <components.Option {...props}>
        <div className="flex justify-start items-center gap-2">
            {!props.data.__isNew__ && <img height={25} width={25} src={props.data.icon} alt={props.data.label} />} <span>{children}</span>
        </div>
    </components.Option>
}

const Control = ({ children, ...props }) => {
    return <components.Control {...props}>
        {(props.selectProps.value && props.selectProps.value.icon) && <img className="ml-2" height={25} width={25} src={props.selectProps.value.icon} alt={props.selectProps.value.label} />} {children}
    </components.Control>
}

const LinksDetailsForm = ({ control, links, socials, url = false, linkFields, socialFields, removeLink, removeSocial, customSelectStyles, handleReset,
    handleNewLinks, handleNewSocials, setValue, trigger, activeStep, handleNext, handleBack, handleSubmit, register, errors, isValid, isAllValid, postToApi }) => {

    const onSubmit = (e) => {
        console.log('onSubmit  e', e)
        isValid && handleNext()
    }

    const isAllFormValid = isAllValid()

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6}>
                {/* LINKS */}
                <Grid item xs={12} >

                    <MDTypography sx={{ marginBottom: "8px" }}>
                        Links
                    </MDTypography>

                    <MDBox className='border border-1 border-inherit rounded-md py-4 px-4'>
                        {linkFields.map((link, index) => (
                            <div className='grid grid-col-3 sm:grid-flow-col py-4 items-baseline gap-2' key={link.id}>
                                <div className=''>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.links ? errorFinder(errors?.links[index]?.linktitle?.message) : false}
                                            label="Link Title"  {...register(`links.${index}.linktitle`)} />

                                        {errors.links &&
                                            <FormHelperText error id="link-title-helper-text">
                                                {errors?.links[index]?.linktitle?.message}
                                            </FormHelperText>}

                                    </FormControl>
                                </div>

                                <div className=''>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.links ? errorFinder(errors?.links[index]?.linkurl?.message) : false}
                                            label="Link Url" {...register(`links.${index}.linkurl`)} />
                                        {errors.links &&
                                            <FormHelperText error id="linkurl-helper-text">
                                                {errors?.links[index]?.linkurl?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <CreatableSelect
                                            name={`links.${index}.linkicon`}
                                            value={links[index]?.linkicon}
                                            onChange={(e) => {
                                                setValue(`links.${index}.linkicon`, e)
                                                trigger(`links.${index}.linkicon`)
                                                return {
                                                }
                                            }}
                                            placeholder="Link Icon"
                                            styles={customSelectStyles}
                                            isClearable
                                            components={{ Control, Option }}
                                            options={CustomOption} />

                                        {errors.links &&
                                            <FormHelperText error id="linkicon-helper-text">
                                                {errors?.links[index]?.linkicon?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                <div className=''>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.links ? errorFinder(errors?.links[index]?.linkdescription?.message) : false}
                                            label="Link Description" {...register(`links.${index}.linkdescription`)} />
                                        {errors.links &&
                                            <FormHelperText error id="linkicon-helper-text">
                                                {errors?.links[index]?.linkdescription?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                {index !== 0 && <div className='my-auto mx-auto shrink'>
                                    <MDButton iconOnly color="error" onClick={() => removeLink(index)}>
                                        <DeleteIcon fontSize='large' />
                                    </MDButton>
                                </div>}
                            </div>
                        ))}

                        <MDBox className='flex mt-4 justify-center gap-2'>
                            <MDButton color="secondary" size="small" variant="outlined" className="flex gap-1 items-center justify-center"
                                onClick={() => {
                                    handleNewLinks()
                                }}>
                                Add New Link
                            </MDButton>
                        </MDBox>
                    </MDBox>

                </Grid>

                {/* SOCIALS */}
                <Grid item xs={12} >

                    <MDTypography sx={{ marginBottom: "8px" }}>
                        Socials
                    </MDTypography>

                    <MDBox className='border border-1 border-inherit rounded-md py-4 px-4'>
                        {socialFields.map((social, index) => (
                            <div className='grid grid-col-3 sm:grid-flow-col py-4 items-baseline gap-2' key={social.id}>

                                <div className=''>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.socials ? errorFinder(errors?.socials[index]?.linktitle?.message) : false}
                                            label="Link Title"  {...register(`socials.${index}.linktitle`)} />
                                        {errors.socials &&
                                            <FormHelperText error id="link-title-helper-text">
                                                {errors?.socials[index]?.linktitle?.message}
                                            </FormHelperText>}

                                    </FormControl>
                                </div>

                                <div className=''>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.socials ? errorFinder(errors?.socials[index]?.linkurl?.message) : false}
                                            label="Link Url" {...register(`socials.${index}.linkurl`)} />
                                        {errors.socials &&
                                            <FormHelperText error id="linkurl-helper-text">
                                                {errors?.socials[index]?.linkurl?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                <div className=' '>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >

                                        <CreatableSelect
                                            name={`socials.${index}.linkicon`}
                                            value={socials[index]?.linkicon}
                                            onChange={(e) => {
                                                setValue(`socials.${index}.linkicon`, e)
                                                trigger(`socials.${index}.linkicon`)
                                                return {
                                                }

                                            }}
                                            placeholder="Link Icon"
                                            styles={customSelectStyles}
                                            isClearable
                                            components={{ Control, Option }}
                                            options={CustomOption} />
                                        {errors.socials &&
                                            <FormHelperText error id="linkicon-helper-text">
                                                {errors?.socials[index]?.linkicon?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                <div className=' '>
                                    <FormControl variant="outlined" sx={{ width: "100%" }} >
                                        <MDInput
                                            error={errors.socials ? errorFinder(errors?.socials[index]?.linkdescription?.message) : false}
                                            label="Link Description" {...register(`socials.${index}.linkdescription`)} />
                                        {errors.socials &&
                                            <FormHelperText error id="linkicon-helper-text">
                                                {errors?.socials[index]?.linkdescription?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                </div>

                                {index !== 0 && <div className='my-auto mx-auto shrink'>
                                    <MDButton iconOnly color="error" onClick={() => removeSocial(index)}>
                                        <DeleteIcon fontSize='large' />
                                    </MDButton>
                                </div>}
                            </div>
                        ))}

                        <MDBox className='flex mt-4 justify-center gap-2'>
                            <MDButton size="small" color="secondary" variant="outlined" className="flex gap-1 items-center justify-center"
                                onClick={() => {
                                    handleNewSocials()
                                }}>
                                {/* <AddCircleIcon fontSize='large' /> */}
                                Add New Social
                            </MDButton>
                        </MDBox>
                    </MDBox>

                </Grid>
            </Grid>

            <Grid item xs={12} className='px-4'>
                <MDTypography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</MDTypography>
                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2, gap: "20px" }}>
                    <MDButton
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color="secondary"
                        sx={{ mr: 1 }}
                        type="button"
                    >
                        Back
                    </MDButton>

                    {!url && <MDButton type="button" color="warning" onClick={() => handleReset()}>
                        Reset
                    </MDButton>}

                    <MDBox sx={{ flex: '1 1 auto' }} />


                    <MDButton sx={(theme) => { return { backgroundColor: theme.palette.primary.main } }} color="dark" type="submit">
                        Next
                    </MDButton>

                    {(url || isAllFormValid) && <MDButton onClick={postToApi} sx={(theme) => { return { backgroundColor: theme.palette.primary.main } }} color="dark" >
                        Submit
                    </MDButton>}
                </MDBox>
            </Grid>

        </form >

    )
}

export default LinksDetailsForm