import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-desktop.jpg";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "redux/globalState"
import { setUserName } from "redux/globalState"
import { Alert, CircularProgress, Collapse, FormHelperText, IconButton } from "@mui/material";
import { useMutate } from "hooks/useMutate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { errorFinder } from "utilities/commonFunctions";

const initialFormData = {
  mobile: "",
  password: ""
}

const schema = yup
  .object()
  .shape({
    mobile: yup.string().required("Mobile Number is Required"),
    password: yup.string().required("Password is Required"),
  })
  .required();


export const SignIn = () => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormData,
    shouldUnregister: false,
  });

  const initialErrorAlertState = { message: "", state: false }
  const [errorAlert, setErrorAlert] = useState(initialErrorAlertState);
  // const [formData, setFormData] = useState(initialFormData)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSuccess = async (response) => {
    //handle success
    if (response.status) {
      dispatch(setLoggedIn(response.access_token))
      dispatch(setUserName(response.username))
      navigate(
        "/dashboard",
      )
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }

  const onError = async (error) => {
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error);
    if (error.status === 401) {
      navigate("/sign-in")
    }
  }

  const { mutate, isLoading } = useMutate("sign-in", `/login.php`, onSuccess, onError)

  const handleFormSubmit = (e) => {
    let bodyFormData = new FormData()
    bodyFormData.append("mobile", e.mobile)
    bodyFormData.append("password", e.password)

    mutate(bodyFormData)
  };

  return (
    <BasicLayout image={bgImage}>

      <Card>
        <MDBox
          bgColor="inherit"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            Log In
          </MDTypography>


          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>

        <MDBox width={"100%"}>
          <Collapse sx={{ marginBottom: "-25px" }} in={errorAlert.state}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(initialErrorAlertState);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
        </MDBox>


        <MDBox pt={4} pb={3} px={3}>


          <MDBox component="form" role="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <MDBox mb={2}>
              <MDInput
                error={errorFinder(errors.mobile)}
                {...register("mobile")}
                type="text" label="Mobile" fullWidth />
              {errors.mobile && <FormHelperText error id="mobile-helper-text">{errors.mobile?.message}</FormHelperText>}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                error={errorFinder(errors.password)}
                {...register("password")}
                type="password" label="Password" fullWidth />
              {errors.password && <FormHelperText error id="password-helper-text">{errors.password?.message}</FormHelperText>}
            </MDBox>
            <MDBox mt={4} mb={1} sx={{ textAlign: "center" }}>
              <MDButton sx={{ fontSize: "1.1rem" }} type="submit" variant="gradient" color="primary">
                {isLoading ? <CircularProgress color="inherit" /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>

          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              Don't have Account? {" "}
              <MDTypography
                component={Link}
                to="/sign-up"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Register Now
              </MDTypography>
            </MDTypography>
          </MDBox>

          <MDBox textAlign="center">
            <MDTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Forgot Password?
            </MDTypography>
          </MDBox>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}
