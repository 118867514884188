// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for DashboardNavbar
import {
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
} from "context";
import {  useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge } from "@mui/material";
import MDTypography from "components/MDTypography";


function DashboardNavbar({ absolute, light, isMini }) {
  // const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  // useEffect(() => {
  //   // Setting the navbar type
  //   if (fixedNavbar) {
  //     setNavbarType("sticky");
  //   } else {
  //     setNavbarType("static");
  //   }

  //   // A function that sets the transparent state of the navbar.
  //   function handleTransparentNavbar() {
  //     // setTransparentNavbar(dispatch, (false));
  //   }

  //   /** 
  //    The event listener that's calling the handleTransparentNavbar function when 
  //    scrolling the window.
  //   */
  //   window.addEventListener("scroll", handleTransparentNavbar);

  //   // Call the handleTransparentNavbar function to set the state with the initial value.
  //   handleTransparentNavbar();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("scroll", handleTransparentNavbar);
  // }, [dispatch, fixedNavbar]);


  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  const purchase = useSelector(state => state.globalState.purchase);

  const userRole = useSelector(state => state.globalState.userRole);

  const isSuperAdmin = userRole === "super_admin";

  return (
    <AppBar
      position={"relative"}
      color="inherit"
      // className=""
      sx={{ background: darkMode ? "#131826" : "#fff" }}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox> */}

        {miniSidenav ? <MDTypography className="headerLogo" variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
          My<span className="logoHighlightColor">B</span>Review<span className="logoHighlightColor">.com</span>
        </MDTypography> : <MDBox>
        </MDBox>}

        {isMini ? null : (
          <MDBox
            sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              <IconButton
                // size="small"
                // disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {/* {miniSidenav ? "menu_open" : "menu"} */}
                  menu
                </Icon>
              </IconButton>

              {isSuperAdmin && <IconButton color="inherit" p={2} component={Link} to="/cart">
                <Badge color="primary" badgeContent={(purchase?.data?.length >= 0) ? purchase?.data?.length : null} >
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>}
              {/* <IconButton
                title="settings"
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon fontSize="medium" sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                title="logout"
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleLogoutUser}
              >
                <LogoutIcon fontSize="medium" sx={iconsStyle}>logout</LogoutIcon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
